import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content/v2'
import Slideshow from '../components/block-content/slideshow'
import Figure from '../components/block-content/figure'

import * as styles from '../components/globals-v2.module.css'
import * as postStyles from '../styles/components/written-post.module.css'
import ScrollMenu from '../components/hub/post/ScrollMenu'
import Popup from '../components/hub/post/Popup'
import Container from '../components/container'
import * as linkStyle from '../styles/components/links.module.css'
import { Image } from '../components/Image'
import Video from '../components/video/video'
import TableGrid from '../components/block-content/tableGrid'

export const query = graphql`
  query WrittenTemplateQuery($id: String!) {
    written: sanityWritten(id: { eq: $id }) {
        _id
        _type
        tags {
            title
            slug {
                current
            }
        }
        title
        blurb
        slug {
            current
        }
        featureImage {
            asset {
                url
                _id
            }
        }
        hideonSinglePage
        author {
            _id
            name
            slug {
                current
            }
            team
            position
            socialTwitter
            socialSubstack
            socialLinkedin
            socialInstagram
            profileColor {
                asset {
                    _id
                    url
                }
            }
            metaDescription
        }
        publishedAt
        _rawContent
        metaTitle
        metaDescription
        metaKeywords
        metaImageFB {
          asset {
              _id
              url
          }
        }
        metaImageTW {
          asset {
              _id
              url
          }
        }
        metaViews
    }
  }
`

const WrittenTemplate = props => {
  const { data } = props
  const written = data && data.written
  const [selectedImage, setSelectedImage] = useState(null)
  const [sources, setSources] = useState([])

  const handleImageClick = (src) => {
    setSelectedImage(src)
  }

  const handleClosePopup = () => {
    setSelectedImage(null)
  }

  // useEffect(() => {
  //   const updateViews = async () => {
  //     try {
  //       // eslint-disable-next-line no-undef
  //       await fetch('/api/updateViews', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ postId: written._id })
  //       })
  //     } catch (error) {
  //       console.error('Error updating views:', error)
  //     }
  //   }
  //
  //   updateViews()
  // }, [written._id])

  let isFirstParagraph = true
  const serializers = {
    types: {
      block: props => {
        switch (props.node.style) {
          case 'h1':
            return <h1>{props.children}</h1>

          case 'h2':
            return <h2 id={props.node._key} className={postStyles.writtenPostContentH2}>{props.children}</h2>

          case 'h3':
            return <h3 className={postStyles.writtenPostContentH3}>{props.children}</h3>

          case 'h4':
            return <h4>{props.children}</h4>

          case 'normal':
            if (isFirstParagraph) {
              isFirstParagraph = false
              return <p className={`${postStyles.writtenPostContentTextFirstParagraph} ${postStyles.writtenPostContentText}`}>{props.children}</p>
            }
            return <p className={postStyles.writtenPostContentText}>{props.children}</p>
          default:
            if (props.children.length === 1 && typeof props.children[0] === 'string' && props.children[0].trim() === '') {
              return ''
            } else {
              return <p className={postStyles.writtenPostContentText}>{props.children}</p>
            }
        }
      },
      figure (props) {
        return <Figure {...props.node} class={postStyles.writtenPostFigure} onClick={handleImageClick} />
      },
      slideshow (props) {
        return <Slideshow {...props.node} />
      },
      videoEmbed (props) {
        if (!props) return null
        return <p><Video videoId={props.node.videoId} addClass='writtenVideoEmbed' type={props.node.youtubePlayer ? 'youtube' : 'standard'} mute={1} /></p>
      },
      embedCode (props) {
        if (props.node.code) {
          return <div dangerouslySetInnerHTML={{ __html: props.node.code }} />
        }
        return <></>
      },
      code: props => (
        <pre className={postStyles.codeBlock}>
          <code>{props.node.code}</code>
        </pre>
      ),
      table (props) {
        if (!props.node) return <></>
        return (<TableGrid tableData={props.node} />)
      }
    },
    list: ({ children, type }) => {
      if (type === 'bullet') {
        return <ul className={postStyles.writtenPostTextList}>{children}</ul>
      }
      if (type === 'number') {
        return <ol className={postStyles.writtenPostTextList}>{children}</ol>
      }
      return null
    },
    listItem: ({ children }) => <li>{children}</li>,
    marks: {
      link: ({ mark, children }) => {
        const { blank, href, sourceLink } = mark

        if (sourceLink && href) {
          const sourceIndex = sources.findIndex(s => s.href === href)
          const index = sourceIndex !== -1 ? sourceIndex + 1 : sources.length + 1

          if (sourceIndex === -1) {
            setSources([{ children, href, index }, ...sources])
          }

          return (
            <span>
              <a href={href} target={blank ? '_blank' : '_self'} rel={blank ? 'noopener noreferrer' : undefined}>
                {children}
              </a>
              <sup>{index}</sup>
            </span>
          )
        }

        return blank
          ? <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
          : <a href={href}>{children}</a>
      }
    }
  }

  const formatDate = (date) => {
    const dateObject = new Date(date)
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    return dateObject.toLocaleString('en-US', options)
  }

  const formatTime = (date) => {
    const dateObject = new Date(date)
    let timeString = dateObject.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })
    const timeArray = timeString.split(' ')
    if (timeArray[1] && (timeArray[1].toUpperCase() === 'PM' || timeArray[1].toUpperCase() === 'AM')) {
      timeString = `${timeArray[0]}${timeArray[1].toLowerCase()} ${timeArray[2]}`
    }
    return timeString
  }

  const getTitlesForScrollList = (content) => {
    const items = []
    for (const item of content) {
      if (item._type === 'block' && item.style === 'h2') {
        items.push({
          id: item._key,
          title: item.children[0].text
        })
      }
    }
    return items
  }

  isFirstParagraph = true
  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' disableScroll={!!selectedImage}>

      <SEO title={written.metaTitle || written.title} description={written.metaDescription} keywords={written.metaKeywords} imageFB={written.metaImageFB} imageTW={written.metaImageTW} />

      <Container class={`${styles.container} ${postStyles.writtenPostContainer}`}>
        <div className={postStyles.writtenPostLeft}>
          <div className={postStyles.stickyLeft}>
            <div style={{ display: 'flex' }}>
              <Link to='/content-hub/' className={`${linkStyle.linkArrow} ${styles.linkArrow} ${linkStyle.back}`}>
                Back
              </Link>
            </div>
            <div className={postStyles.writtenPostAuthorInfo}>
              {written.author.map(author => {
                return (
                  <div key={author._id} className={postStyles.writtenPostAuthor}>
                    <div
                      style={{ backgroundImage: `url(${author.profileColor && `${author.profileColor.asset.url}`})` }}
                      className={postStyles.writtenPostAuthorInfoIcon}
                    />
                    <div className={postStyles.writtenPostAuthorTextContainer}>
                      <div className={postStyles.writtenPostAuthorTitle}>{author.name}</div>
                      <div
                        className={postStyles.writtenPostAuthorText}
                      >{author.position}, {author.team.join(', ')}
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className={postStyles.writtenPostAuthorDate}>
                <div className={postStyles.writtenPostAuthorDateTitle}>{formatDate(written.publishedAt)}</div>
                {/* <div className={postStyles.writtenPostAuthorDateText}>{formatTime(written.publishedAt)}</div> */}
              </div>
            </div>
            <ScrollMenu title='Outline' elements={getTitlesForScrollList(written._rawContent)} />
          </div>
        </div>

        <div className={postStyles.writtenPostContent}>
          <h1 className={postStyles.writtenPostTitle}>
            {written.title}
          </h1>
          {!written.hideonSinglePage ? (<Image imgObj={written.featureImage} className={postStyles.writtenPostImage} style={{ cursor: 'zoom-in' }} onClick={() => handleImageClick(written.featureImage)} />) : ''}
          <div className={` ${postStyles.writtenPostAuthorInfo} ${postStyles.writtenPostAuthorInfoMobile}`}>
            {written.author.map(author => {
              return (
                <div key={author._id} className={postStyles.writtenPostAuthor}>
                  <div
                    style={{ backgroundImage: `url(${author.profileColor && `${author.profileColor.asset.url}`})` }}
                    className={postStyles.writtenPostAuthorInfoIcon}
                  />
                  <div className={postStyles.writtenPostAuthorTextContainer}>
                    <div className={postStyles.writtenPostAuthorTitle}>{author.name}</div>
                    <div className={postStyles.writtenPostAuthorText}>{author.position}, {author.team.join(', ')}</div>
                  </div>
                </div>
              )
            })}
            <div className={postStyles.writtenPostAuthorDate}>
              <div className={postStyles.writtenPostAuthorDateTitle}>{formatDate(written.publishedAt)}</div>
              {/* <div className={postStyles.writtenPostAuthorDateText}>{formatTime(written.publishedAt)}</div> */}
            </div>
          </div>
          <div id='postContent'>
            <BlockContent blocks={written._rawContent} serializers={serializers} />
          </div>
          {sources.length > 0 && (
            <div className={postStyles.sources}>
              <h2 className={postStyles.writtenPostContentH2}>Sources</h2>
              <ol className={postStyles.sourceList}>
                {sources.map(source => (
                  <li key={source.index}>
                    <a href={source.href} target='_blank' rel='noopener noreferrer'>{source.children}</a>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
        <Popup imageSrc={selectedImage} onClose={handleClosePopup} />
      </Container>
    </Layout>
  )
}

export default WrittenTemplate
