import React, { useEffect, useRef, useState } from 'react'
import * as styles from './popup.module.css'
import { Image } from '../../Image'
import { useClickOutside } from '../../../hooks/useClickOutside'

const Popup = ({ imageSrc, onClose }) => {
  const [show, setShow] = useState(false)
  const imgRef = useRef(null)
  useClickOutside(imgRef, show ? onClose : () => {})

  useEffect(() => {
    if (imageSrc) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [imageSrc])

  if (!imageSrc) return null

  return (
    <div className={`${styles.popupOverlay} ${show ? styles.show : ''}`}>
      <div className={styles.popupContent}>
        <div className={styles.popupClose}>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M19.7115 2.56199L12.354 10.2417L4.7981 2.41016L2.92214 4.28612L10.7316 11.768L2.71289 19.5045L4.59609 21.3877L12.2581 13.3902L19.8698 21.2796L21.748 19.4014L13.8804 11.8639L21.5825 4.43295L19.7115 2.56199Z'
              fill='#E3E3D5'
            />
          </svg>
        </div>
        <div ref={imgRef}>
          <Image imgObj={imageSrc} props={{ queryParams: { fit: 'max', q: 100 } }} alt='Popup' />
        </div>
      </div>
    </div>
  )
}

export default Popup
