import React, { useEffect } from 'react'

import styles from './scroll-menu.module.css'

function ScrollMenu ({ title, elements }) {
  const clickhandler = (e) => {
    e.preventDefault()
    const href = e.target.getAttribute('href')
    const el = document.getElementById(href)
    if (el) {
      const offsetTop = el.offsetTop - 110
      // eslint-disable-next-line no-undef
      scroll({
        top: offsetTop,
        behavior: 'smooth'
      })
    }
  }

  // const isElementVisible = (elementId) => {
  //     const windowHeight = window.innerHeight
  //     const visibilityHeight = windowHeight / 2
  //     const el = document.getElementById(elementId)

  //     if (el) {
  //         const elSquare = el.getBoundingClientRect()
  //         const elPositionTop = elSquare.top
  //         const elPositionBottom = elSquare.bottom
  //         console.log(el, elPositionTop, elPositionBottom, visibilityHeight)
  //         return visibilityHeight > elPositionTop && visibilityHeight < elPositionBottom
  //     } else {
  //         return false
  //     }
  // }

  const handleScroll = () => {
    let lastElId = elements[0]?.id

    if (!lastElId) return

    for (const element of elements) {
      // console.log(element.id, isElementVisible(element.id))
      const el = document.getElementById(element.id)
      // if (el && isElementVisible(element.id)) {
      //     el.classList.add('scrollMenuTextSelected')
      // } else if (el) {
      //     el.classList.remove('scrollMenuTextSelected')
      // }
      if (el) {
        const windowHeight = window.innerHeight
        const visibilityHeight = windowHeight / 2
        const elPositionTop = el.getBoundingClientRect().top
        if (elPositionTop < visibilityHeight) {
          lastElId = element.id
        }
      }
    }
    const active = document.querySelector(`.${styles.scrollMenuTextSelected}`)
    if (active) {
      active.classList.remove(styles.scrollMenuTextSelected)
    }
    const lastEl = document.getElementById(`scroll-list_${lastElId}`)
    lastEl.classList.add(styles.scrollMenuTextSelected)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [elements])

  return (
    <div className={styles.scrollMenu}>
      {elements.length > 0 && (
        <>
          <div className={styles.scrollMenuTitle}>{title}</div>
          <div className={styles.scrollMenuContainer}>
            {elements.map(item => (
              <a
                id={`scroll-list_${item.id}`}
                href={item.id}
                key={item.id}
                className={styles.scrollMenuText}
                onClick={clickhandler}
              >
                {item.title}
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default ScrollMenu
