import { useEffect } from 'react'

export const useClickOutside = (ref, onFunction) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current || (ref.current && !ref.current.contains(event.target))) {
        onFunction()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, onFunction])
}
